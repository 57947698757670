import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";
import { Route, Redirect, withRouter } from "react-router-dom";

import { brandName, brandTypes } from "b2c/constants/brand";
import { getPathRoot } from "utils/helpers";
import useStore from "./store";
import NotFound from "../pages/NotFound";

const BrandedContext = createContext({});

const routes = [
  "/",
  "/wizard",
  "/jobs",
  "/profile",
  "/application",
  "/premium",
  "/products",
  "/product",
  "/company",
  "/login",
  "/reset",
  "/signup",
  "/onboarding",
  "/pro/auth/linkedin/callback",
  "/pro/password/edit"
];

class BrandedModeCore extends React.Component {
  static propTypes = {
    brand: PropTypes.object.isRequired,

    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  setBranded = props => {
    this.setState({ ...props, setBranded: this.setBranded });
  };

  state = {
    setBranded: this.setBranded,
    ...this.props.brand
  };

  brandedModeRedirection = () => {
    const root = getPathRoot(this.props?.location?.pathname);
    const replace = this.props.history?.replace;
    const jobsCountryRoutes = this.props?.countries?.length
      ? this.props.countries?.map(({ slug }) => `/${slug}/jobs/:id`)
      : [];
    const newPaths = [...routes, ...jobsCountryRoutes];

    if (
      !newPaths.some(route => root === route) &&
      !newPaths.some(route => jobsCountryRoutes.includes(route)) &&
      this.state.brand_type === brandTypes.whitelabelled
    ) {
      replace("/");
    }
  };

  componentDidMount() {
    this.brandedModeRedirection();
  }

  componentDidUpdate() {
    this.brandedModeRedirection();
  }

  render() {
    return <BrandedContext.Provider value={this.state}>{this.props.children}</BrandedContext.Provider>;
  }
}

export const matchBrand = (brand, brandSlug) => {
  if (brand.slug === brandSlug) return true;
  if (brandSlug.includes(brand.slug)) return true;

  return !!brand.inherit_brands.includes(brandSlug);
};

export const BrandHidden = ({ brand, children }) => {
  const currentBrand = useContext(BrandedContext);

  return matchBrand(currentBrand, brand) ? null : children;
};

export const isMatchBrand = brands => {
  const currentBrand = useContext(BrandedContext);

  return matchBrand(currentBrand, brands);
};

export const BrandVisible = ({ brand = "www", children }) => {
  const currentBrand = useContext(BrandedContext);

  return matchBrand(currentBrand, brand) ? children : null;
};

export const WhitelabelledOrCustomHidden = ({ children }) => {
  return isWhitelabelledOrCustomBranded() ? null : children;
};

export const WhitelabelledOrCustomVisible = ({ children }) => {
  return isWhitelabelledOrCustomBranded() ? children : null;
};

export const WhitelabelledHidden = ({ children }) => {
  const { brand_type } = useContext(BrandedContext);

  return brand_type === brandTypes.whitelabelled ? null : children;
};

export const WhitelabelledOrJkuatHidden = ({ children }) => {
  const { brand_type: brandType } = useContext(BrandedContext);
  const customJkuat = isCustomJkuat();

  return brandType === brandTypes.whitelabelled || customJkuat ? null : children;
};

export const WhitelabelledVisible = ({ children }) => {
  const { brand_type } = useContext(BrandedContext);

  return brand_type === brandTypes.whitelabelled ? children : null;
};

export const WhitelabelledOrJkuatVisible = ({ children }) => {
  const { brand_type: brandType } = useContext(BrandedContext);

  const customJkuat = isCustomJkuat();

  return brandType === brandTypes.whitelabelled || customJkuat ? children : null;
};

export const withBrandedRoute =
  Component =>
  ({ path, ...props }) => {
    const { brand_type } = useContext(BrandedContext);

    const component = isCustomPlatformLoggedIn() ? () => <Redirect exact from={path} to="/home" /> : Component;

    return !!brand_type && brand_type !== brandTypes.normal ? (
      <Route component={component} path={path} {...props} />
    ) : null;
  };

export const withBrandedRedirect = Component => props => {
  const { brand_type } = useContext(BrandedContext);
  const { name } = useContext(BrandedContext);
  const isKepsa = name === brandName.kepsa;

  return brand_type === brandTypes.whitelabelled && !isKepsa ? <Redirect to="/" /> : <Component {...props} />;
};

export const withCustomPlatformHidden = Component => props => {
  return isCustomPlatform() ? <NotFound /> : <Component {...props} />;
};

export const isNormalFuzu = () => {
  const { brand_type } = useContext(BrandedContext);
  return brand_type === brandTypes.normal || !brand_type;
};

export const isWhitelabelled = () => {
  const { brand_type } = useContext(BrandedContext);
  return brand_type === brandTypes.whitelabelled;
};

export const isCustomJkuat = () => {
  const { slug } = useContext(BrandedContext);
  const customPlatform = isCustomPlatform();

  return customPlatform && slug === brandName.jkuat;
};

export const isKepsa = () => {
  const { name } = useContext(BrandedContext);

  return name === brandName.kepsa;
};

export const isBaronaBrand = () => {
  const { name } = useContext(BrandedContext);

  return name?.includes(brandName.barona);
};

export const isBaronaCareersBrand = () => {
  const { name } = useContext(BrandedContext);

  return name?.includes(brandName.baronaCareers);
};

export const getCompanyName = () => {
  const { name } = useContext(BrandedContext);

  return name?.includes(brandName.barona) ? "Barona" : "Fuzu";
};

export const buildImageUrlS3 = name => {
  const { global_brand } = useStore("initialState");

  return `${global_brand.assets_store}/images/${name}`;
};

export const isWhitelabelledOrJkuat = () => {
  const { brand_type: brandType, slug } = useContext(BrandedContext);
  return (
    brandType === brandTypes.whitelabelled || (brandType === brandTypes.customPlatform && slug === brandName.jkuat)
  );
};

export const isNCPWD = () => {
  const { slug } = useContext(BrandedContext);
  return slug === brandName.ncpwd;
};

export const isWhitelabelledOrCustomBranded = () => {
  const { brand_type } = useContext(BrandedContext);
  return brand_type === brandTypes.whitelabelled || brand_type === brandTypes.customPlatform;
};

export const isCustomPlatform = () => {
  const { brand_type } = useContext(BrandedContext);
  return brand_type === brandTypes.customPlatform;
};

export const isCustomPlatformAndNotJkuat = () => {
  const { brand_type, slug } = useContext(BrandedContext);

  return brand_type === brandTypes.customPlatform && slug !== brandName.jkuat;
};

export const isCustomPlatformLoggedIn = () => {
  const { user } = useStore("User");
  const { brand_type } = useContext(BrandedContext);
  return brand_type === brandTypes.customPlatform && !!user?.id;
};

export const isCustomPlatformGuest = () => {
  const { user } = useStore("User");
  const { brand_type } = useContext(BrandedContext);
  return brand_type === brandTypes.customPlatform && !user?.id;
};

export const MarketPromoHidden = ({ bypass = false, children }) => {
  const { hide_product_marketing } = useContext(BrandedContext);

  return !bypass && hide_product_marketing ? null : children;
};

export const BrandedModeProvider = withRouter(BrandedModeCore);

export default BrandedContext;
