import { makeAutoObservable, reaction, when, flow, toJS } from "mobx";

import { stringify } from "utils/qsUtil";
import { addQueryParams, buildPath } from "utils/hooks";
import * as State from "utils/storeHelpers";
import { cleanObject } from "utils/helpers";
import API from "../../utils/api";
import JobSearchUrlBuilder from "./JobSearchUrlBuilder";
import { jobSettings } from "../../constants/jobs";

class JobSearch {
  setAppState;

  countries = [];

  getLocations;

  company_details = {};

  job_details = {};

  prefieldJobId = "";

  disable;

  jobSearchUrlBuilder;

  isGuestPage = false;

  isSelectedCurrentCountry = false;

  constructor(setAppState, CommonStore, context = {}, initialStateUser) {
    makeAutoObservable(this);

    this.isGuestPage = !initialStateUser?.id;
    this.setAppState = setAppState;
    this.getLocations = CommonStore.getLocations;
    this.company_details = context.company_details || {};
    this.job_details = context.job_details || {};
    this.h1_title = context.h1_title;
    if (context.page_info) {
      this.ssr = true;
      this.page_type = context.page_type;
      this.resetSlug = false;
      this.results = context.page_info.jobs;
      this.totalCount = context.page_info.total_count;
      this.activeCount = context.page_info.active_count;
      this.pageInfo = { path: "/job", disable: true };
      this.searchFilters = context.page_info.available_filters;
      this.params.appliedFilters = context.page_info.selected_filters;
      this.countries = [...context.page_info.available_filters.countries, ...context.page_info.available_filters.towns];
      this.state.loading = false;
      this.disable = true;
    }
    this.jobSearchUrlBuilder = new JobSearchUrlBuilder(
      toJS(this.searchFilters),
      toJS(this.countries),
      toJS(this.params)
    );

    /*  remove this part of the code when we remove the all-countries list
    autorun(() => {
      this.countries = CommonStore.countries;

      return this.countries;
    });
    */

    reaction(
      () => ({ ...this.params }),
      params => {
        if (this.loadingPageInfo) {
          this.loadingPageInfo = false;
        } else {
          if (this.pendingFlows.length > 0) {
            this.pendingFlows.forEach(async flows => await flows.cancel());
            this.pendingFlows = [];
          }

          this.jobSearchUrlBuilder = new JobSearchUrlBuilder(
            toJS(this.searchFilters),
            toJS(this.countries),
            toJS(this.params)
          );

          const defaultCountryId = this.languageAndLanguage ? "" : this.defaultCountryId;
          const pageInfo = this.jobSearchUrlBuilder.build(
            this.browserHistory.location.pathname,
            false,
            defaultCountryId,
            this.languageAndLanguage
          );

          const notPageInfoUrls = ["/job", "/job/saved", "/job/applications", "/job/settings"];

          /* Add country if lost start */
          const updatedPageInfo = {
            ...pageInfo,
            query: {
              ...pageInfo.query,
              filters: {
                ...pageInfo.query.filters,
                country_id: pageInfo.query.country_id || this.params.appliedFilters.country_id || ""
              }
            }
          };
          /* Add country if lost end */

          if (!this._isPerformedRequest() && this.fetchByPageInfoApi) {
            this.pendingFlows = this.pendingFlows.concat(this.getPageInfoGen(this.appliedPageInfo));
          } else if (Object.entries(this.appliedPageInfo).length && !notPageInfoUrls.includes(pageInfo.path)) {
            if (this.browserHistory && !this.isNotCallBrowserHistory) {
              addQueryParams(pageInfo.query, toJS(this.browserHistory), pageInfo.path);
            }
            this.pendingFlows = this.pendingFlows.concat(this.getPageInfoGen(updatedPageInfo));
          } else {
            const queryParams = {
              page: params.page,
              filters: { ...params.appliedFilters }
            };
            const filters = { ...params.appliedFilters };
            delete filters.town_id;
            const searchQuery = stringify(queryParams);
            const filterQuery = stringify({ filters });

            if (this.browserHistory) {
              addQueryParams(queryParams, toJS(this.browserHistory), this.seoMode && "/job");
            }
            this.pendingFlows = this.pendingFlows.concat(this.getSearchResults(filterQuery, searchQuery));
          }

          this.appliedPageInfo = pageInfo;
        }
      }
    );

    reaction(
      () => this.state,
      localState => {
        this.setAppState(localState);
      }
    );

    when(
      () => this.state?.status === 404 && !!this.browserHistory,
      () => {
        this.browserHistory?.push("/job");
      }
    );

    when(
      () =>
        !this.state.loading &&
        !!this.params?.appliedFilters?.job_id &&
        this.params?.page === 1 &&
        !this.results?.length &&
        !this.results?.some(({ id }) => id === this.params?.appliedFilters?.job_id),
      () => {
        this.positionNotFound = true;
      }
    );
  }

  defaultState = {
    loading: true,
    type: "",
    message: ""
  };

  state = this.defaultState;

  meta;

  page_type;

  results = [];

  totalCount = 0;

  defaultCountry;

  defaultCountryId = "";

  activeCount = 0;

  searchFilters = {};

  params = {
    appliedFilters: {},
    page: 1,
    per_page: 10
  };

  applicationFilters;

  browserHistory;

  type = "jobs";

  h1_title;

  settings;

  resetSlug = false;

  navigationMethod;

  seoMode = false;

  settingSections = ["preferred_countries", "seniority_levels", "interest_areas"];

  pendingFlows = [];

  positionNotFound = false;

  pageInfo = {};

  appliedPageInfo = {};

  commonCountries = [];

  globalLocations = [];

  commonCities = [];

  loadingPageInfo = false;

  fetchByPageInfoApi = false;

  getPositions = API.getJobSearchResult;

  get appliedCount() {
    return Object.keys(this.params.appliedFilters).length;
  }

  setNavigationMethod = method => {
    this.navigationMethod = method;
  };

  initializeJobSearch = async (pathname, query, history, slug, ml_active, job_id, authorized, disable = false) => {
    const updateHistory =
      history.location.pathname !== pathname ? { ...history, location: { ...history.location, pathname } } : history;

    this.browserHistory = updateHistory;
    this.fetchByPageInfoApi = false;
    if (!disable && !this.disable) {
      this.results = [];
      this.seoMode = false;
      this.type = "jobs";
      this.positionNotFound = false;
      this.getPositions = API.getJobSearchResult;
      if (slug === "settings") return this.initializeSettings(ml_active);

      if (slug === "applications") {
        this.type = "applications";
        this.getPositions = API.getApplications;
        await this.getApplicationFilters();
      } else if (slug === "saved") this.getPositions = API.getSavedJobs;
      else if (!!slug || history.location.pathname.indexOf("/job") > 0) this.fetchByPageInfoApi = true;

      this.page_type = "jobs_search_page";

      if (!this.fetchByPageInfoApi || !Object.entries(this.appliedPageInfo).length) {
        if (!Object.entries(this.appliedPageInfo).length) {
          this.appliedPageInfo = { path: pathname, query };
        }
        const page = query.filters?.job_id ? 1 : parseInt(query?.page || 1);
        this.setParams({
          appliedFilters: query.filters || {},
          page,
          ...(job_id ? { filters: { job_id } } : {})
        });
      }
    }

    if (!Object.entries(this.appliedPageInfo).length) {
      this.appliedPageInfo = { path: pathname, query };
    }
    this.disable = false;
    this.ssr = false;
  };

  getPageInfoGen = flow(function* (pageInfo) {
    yield this.getPageInfo(pageInfo);
  });

  getPageInfo = async ({ path, query }) => {
    if (this.ssr) {
      return;
    }

    try {
      this.pageInfo = {};
      this.meta = {};
      this.state = State.setLoading(this.state);
      const pageInfoQuery = {
        path,
        ...query,
        filters: query.filters
          ? { ...query.filters, job_id: this.prefieldJobId || query.filters.job_id }
          : { job_id: this.prefieldJobId || "" }
      };
      const res = await API.getPageInfoByQuery(stringify(pageInfoQuery));
      this.prefieldJobId = "";
      const { page_info, page_type, h1_title, meta_title, meta_description, links, index_follow } = res.data || {};
      this.page_type = res?.data?.page_type;
      if (page_type === "jobs_search_page") {
        this.loadingPageInfo = true;
        this.resetSlug = true;
        this.seoMode = true;
        this.searchFilters = page_info.available_filters;
        this.params.appliedFilters = page_info.selected_filters;
        this.prefieldJobId = page_info.selected_filters.job_id;
        this.results = page_info.jobs;
        this.meta.title = meta_title;
        this.meta.description = meta_description;
        this.meta.links = links;
        this.meta.index_follow = index_follow ? "index,follow" : "noindex,nofollow";
        this.h1_title = h1_title;
        this.totalCount = page_info.total_count;
        this.activeCount = page_info.active_count;

        const defaultCountryId = this.languageAndLanguage ? "" : this.defaultCountryId;

        const builder = new JobSearchUrlBuilder(toJS(this.searchFilters), toJS(this.countries), toJS(this.params));
        const pageInfo = builder.build("/job", false, defaultCountryId, this.languageAndLanguage);
        this.pageInfo = { path: buildPath(pageInfo.path, pageInfo.query), disable: true };
      } else if (page_info?.job) this.job_details = page_info?.job;
      else if (page_info?.expired) {
        this.pageInfo = { path: `/job/${page_info?.category?.slug}`, disable: false, expired: true };
        return;
      }
      this.state = State.setNeutral(this.state, res);
    } catch (err) {
      this.state = State.setError(this.state, err, "Invalid path");
    }
  };

  getTownJobsByTown = ({ path, filters }) => {
    this.state = State.setLoading(this.state);

    const pageInfoQuery = {
      path,
      filters: filters
        ? { ...filters, job_id: this.prefieldJobId || filters.job_id }
        : { job_id: this.prefieldJobId || "" }
    };
    API.getPageInfoByQuery(stringify(pageInfoQuery))
      .then(res => {
        const { page_info, page_type, h1_title, meta_title, meta_description, links, index_follow } = res.data || {};
        this.page_type = res?.data?.page_type;
        if (page_type === "jobs_search_page") {
          this.isNotCallBrowserHistory = true;
          this.searchFilters = page_info.available_filters;
          this.params.appliedFilters = page_info.selected_filters;
          this.prefieldJobId = page_info.selected_filters.job_id;
          this.results = page_info.jobs;
          this.meta.title = meta_title;
          this.meta.description = meta_description;
          this.meta.links = links;
          this.meta.index_follow = index_follow ? "index,follow" : "noindex,nofollow";
          this.h1_title = h1_title;
          this.totalCount = page_info.total_count;
          this.activeCount = page_info.active_count;

          this.state = State.setLoading({ ...this.state, loading: false });
          this.state = State.setNeutral(this.state, res);

          const builder = new JobSearchUrlBuilder(toJS(this.searchFilters), toJS(this.countries), toJS(this.params));

          const pageInfo = builder.build("/job", false, this.defaultCountryId, this.languageAndLanguage);

          this.pageInfo = { path: buildPath(pageInfo.path, pageInfo.query), disable: true };
        }
      })
      .catch(error => {
        this.state = State.setError(this.state, error, "Invalid path");
      });
  };

  setParams = params => {
    this.params = { ...this.params, ...params };
  };

  setPage = page => {
    this.params.page = +page;
  };

  setDefaultCountryId = id => {
    this.defaultCountryId = id;
  };

  applyFilter = filters => {
    const { job_id, country_id, ...rest } = this.params.appliedFilters;
    const { languageAndLanguage, ...restFilters } = filters;
    let currentCountryId = "";

    this.languageAndLanguage = languageAndLanguage;
    if (this.isSelectedCurrentCountry) {
      this.defaultCountryId = country_id || this.defaultCountryId;
      currentCountryId = this.defaultCountryId;
    } else {
      currentCountryId = restFilters.country_id;
    }

    const appliedFilters = { ...rest, ...restFilters, country_id: currentCountryId };
    const cleanedFilters = cleanObject(appliedFilters);
    const newFilters = languageAndLanguage ? { ...cleanedFilters, country_id: null } : cleanedFilters;

    this.prefieldJobId = "";
    this.params = {
      ...this.params,
      appliedFilters: { ...newFilters },
      page: 1
    };
  };

  resetFilters = languageAndLanguage => {
    this.languageAndLanguage = languageAndLanguage;

    const countryId =
      this.isSelectedCurrentCountry && !languageAndLanguage
        ? this.defaultCountryId || this.params.appliedFilters.country_id
        : "";

    this.params = {
      appliedFilters: {
        country_id: countryId
      },
      page: 1
    };
  };

  getPosition = id => {
    if (this.job_details.id !== id) {
      this.state = State.setLoading(this.state);
      return API.getPosition(id)
        .then(res => {
          this.job_details = res?.data?.job;
          this.state = State.setNeutral(this.state);
        })
        .catch(err => {
          this.state = State.setError(this.state, err, "Failed to retrieve position details", () =>
            this.getPosition(id)
          );
        });
    }
  };

  getCompanyDetails = (job_id, company_identifier, isKepsa) => {
    const notCompany =
      this.company_details?.id !== company_identifier && this.company_details?.slug !== company_identifier;
    if (job_id !== this.company_details.position_id || notCompany || (!this.company_details?.id && isKepsa)) {
      this.company_details = {};
      let fetchDetails;
      let id;
      let field;

      if (company_identifier) {
        fetchDetails = API.getCompanyDetailsByCompanyId;
        id = company_identifier;
        field = isKepsa ? "employability_program" : "company";
      } else if (job_id) {
        fetchDetails = API.getCompanyDetailsByJobId;
        id = job_id;
        field = "employer";
      }

      return fetchDetails(id)
        .then(res => {
          this.company_details = {
            position_id: job_id,
            ...res.data[field]
          };
          this.state = State.setNeutral(this.state);
        })
        .catch(err => {
          this.state = State.setError(this.state, err, "Failed to retrieve company details", () =>
            this.getCompanyDetails(job_id, company_identifier)
          );
          this.state = State.setNeutral(this.state);
        });
    }
  };

  initializeSettings = ml_active => {
    this.page_type = "jobs_search_page";
    if (!this.settings) {
      this.state = State.setLoading(this.state);
      if (ml_active)
        this.settingSections = this.settingSections.filter(
          setting => !["seniority_levels", "interest_areas"].includes(setting)
        );
      return Promise.all(this.settingSections.map(setting => this.getSettings(setting)))
        .then(() => {
          this.state = State.setNeutral(this.state);
        })
        .catch(err => {
          this.state = State.setError(this.state, err, "Failed to retrieve search settings", () =>
            this.initializeSettings(ml_active)
          );
        });
    }
  };

  getApplicationFilters = () => {
    if (!this.applicationFilters) {
      return API.getApplicationFilters()
        .then(res => {
          this.applicationFilters = res?.data?.filters;
        })
        .catch(err => {
          this.state = State.setError(this.state, err, "Failed to retrieve application filters", () =>
            this.getApplicationFilters()
          );
        });
    }
    return Promise.resolve();
  };

  getSettings = setting_name => {
    const get = (() => {
      switch (setting_name) {
        default:
        case "preferred_countries":
          return API.getPreferredCountries;
        case "seniority_levels":
          return API.getSenioritySettings;
        case "interest_areas":
          return API.getInterestSettings;
      }
    })();
    return get()
      .then(res => {
        this.settings = { ...this.settings, ...res.data };
      })
      .catch(err => {
        this.state = State.setError(this.state, err, `Failed to retrieve ${setting_name.replace(/_/g, " ")}`, () =>
          this.getSettings(setting_name)
        );
      });
  };

  updateSettings = (list, settingName) => {
    const update = (() => {
      switch (settingName) {
        default:
        case "preferred_countries":
          return API.updatePreferredCountries;
        case "seniority_levels":
          return API.udpateSenioritySettings;
        case "interest_areas":
          return API.updateInterestSettings;
      }
    })();
    return update(list)
      .then(res => {
        const updateSettingName =
          settingName === jobSettings.preferredCountries ? jobSettings.preferredLocations : settingName;

        this.settings[updateSettingName] = res.data[updateSettingName];

        this.state = State.setSuccess(this.state, res, "Settings updated successfully");
      })
      .catch(err => {
        this.state = State.setError(this.state, err, `Failed to update ${settingName.replace(/_/g, " ")}`, () =>
          this.updateSettings(list, settingName)
        );
      });
  };

  trackJobView = id => {
    return API.trackJobView(id).catch(err => {
      console.error(err);
    });
  };

  getCommonLocations = query => {
    return API.getCommonLocations(query)
      .then(res => {
        this.commonCountries = res.data.results;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to get common countries", () =>
          this.getCommonLocations(query)
        );
      });
  };

  getCommonCities = (countryId, search) => {
    return API.getCommonCities(countryId, search)
      .then(res => {
        this.commonCities = res.data.results;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to get common cities", () =>
          this.getCommonCities(countryId, search)
        );
      });
  };

  getGlobalLocations = search => {
    this.state = { ...this.state, loading: true };
    return API.getGlobalLocations(search)
      .then(res => {
        this.state = { ...this.state, loading: false };
        this.globalLocations = res.data.results;

        return this.globalLocations;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Failed to get global locations", () =>
          this.getGlobalLocations(search)
        );
      });
  };

  clearGlobalLocations = () => {
    this.globalLocations = [];
  };

  removeApplication = job_id => {
    const position = this.results.find(({ id }) => id === job_id);
    if (position) {
      const modified = [...this.results];
      const positionIndex = this.results.indexOf(position);
      this.type === "applications"
        ? modified.splice(positionIndex, 1)
        : (modified[positionIndex] = { ...position, application: null });

      this.results = modified;
    }
    if (this.job_details?.id == job_id) {
      this.job_details = { ...this.job_details, application: null };
    }
  };

  getSearchResults = flow(function* (filterQuery, searchQuery) {
    this.state = State.setLoading(this.state);

    try {
      const res = yield Promise.all([
        this.type === "jobs" ? API.getPositionFilters(filterQuery) : Promise.resolve({ data: { filters: [] } }),
        this.getPositions(searchQuery)
      ]);
      this.results = res[1].data.jobs;
      this.totalCount = res[1].data.meta.total_count;
      this.activeCount = res[1].data.meta.active_count;
      this.searchFilters = res[0].data.filters;

      if (!this.countries?.length && res[0].data.filters.countries?.length) {
        this.countries = [...res[0].data.filters.countries, ...res[0].data.filters.towns];
      }

      this.state = State.setNeutral(this.state, res);
    } catch (err) {
      this.state = State.setError(this.state, err, "Failed to get search results", () =>
        this.getSearchResults(filterQuery, searchQuery)
      );
    }
  });

  addIdParameter = job_id => {
    if (this.browserHistory) {
      const { query = {} } = this.appliedPageInfo;
      const builtQuery = { ...query, filters: { ...(query.filters || {}), job_id } };
      addQueryParams(builtQuery, toJS(this.browserHistory));
    }
  };

  unassignHistory = () => {
    this.browserHistory = null;
  };

  clearPageInfo = () => {
    this.appliedPageInfo = {};
    this.h1_title = "";
    this.clearState();
  };

  clearState = () => {
    this.state = this.defaultState;
  };

  setIsSelectedCurrentCountry = isSelectedCurrentCountry => {
    this.isSelectedCurrentCountry = isSelectedCurrentCountry;
  };

  _isPerformedRequest = () => {
    const { loading, processing } = this.state;

    return !loading && !processing;
  };
}

export default JobSearch;
