import React from "react";
import parse, { domToReact } from "html-react-parser";

import mobileGlobal from "images/b2c/landing-page/mobile-hero-global.jpg";
import mobileHeroKE from "images/b2c/landing-page/mobile-hero-ke.jpg";
import mobileHeroUG from "images/b2c/landing-page/mobile-hero-ug.jpg";
import mobileHeroNG from "images/b2c/landing-page/mobile-hero-ng.jpg";
import cityGlobal from "images/b2c/landing-page/cityscape-global.png";
import cityKE from "images/b2c/landing-page/cityscape-ke.png";
import cityUG from "images/b2c/landing-page/cityscape-ug.png";
import cityNG from "images/b2c/landing-page/cityscape-ng.png";
import ListMarkerYellow from "images/b2c/icons/listMarkerYellow.svg";
import { WEB_PUSH_PUBLIC_KEY } from "b2c/constants/pwa";
import { notificationPermissionTypes } from "b2c/constants/main";
import { scrollToError } from "b2c/constants/wizard";
import { Label, List, Text } from "../../components/Core";
import { validateEmail } from "../../../utils/validation";

export const getCityScape = slug => {
  switch (slug) {
    default:
      return cityGlobal;
    case "kenya":
      return cityKE;
    case "uganda":
      return cityUG;
    case "nigeria":
      return cityNG;
  }
};

export const getMobileImage = slug => {
  switch (slug) {
    default:
      return mobileGlobal;
    case "kenya":
      return mobileHeroKE;
    case "uganda":
      return mobileHeroUG;
    case "nigeria":
      return mobileHeroNG;
  }
};

const validateTextLength = (input, limit) => {
  if (input) {
    if (input.length > limit) {
      return { test: false, error: `Max ${limit} character` };
    }
    return { test: true };
  }

  return { test: false, error: "Field is required" };
};

export const validateFormHeroCard = ({ name, thank, email, careerName }) => {
  const validation = {
    name: validateTextLength(name, 40),
    careerName: validateTextLength(careerName, 25),
    thank: validateTextLength(thank, 300),
    email: validateEmail(email)
  };

  const valid = Object.keys(validation).every(key => validation[key].test);

  return { validation, valid };
};

export const objectiveParser = raw => {
  return raw
    ? parse(raw.replace("&nbsp;", " "), {
        replace: domNode => {
          if (domNode.name === "ul") {
            domNode.name = "div";
            domNode.attribs.class = "list-vertical";
            return domNode;
          }
          if (domNode.name === "li") {
            return (
              <Label
                Icon={<ListMarkerYellow style={{ marginRight: "15px" }} />}
                lineHeight="26px"
                noBackground
                color="black500"
                fontSize={14}
                fontWeight={400}
                padding="0"
                margin="0 0 5px"
              >
                {domToReact(domNode.children)}
              </Label>
            );
          }
          return <></>;
        }
      })
    : null;
};

export const formatOptions = {
  replace: domNode => {
    if (domNode.name === "a") {
      domNode.attribs.class = "link underlined";
      return domNode;
    }
    if (domNode.name === "ul") {
      return <List style={{ margin: "5px 0" }}>{domToReact(domNode.children, formatOptions)}</List>;
    }
    if (domNode.name === "li") {
      return <List.Item lineHeight="140%">{domToReact(domNode.children, formatOptions)}</List.Item>;
    }
    if (domNode.name === "p") {
      return (
        <Text fontSize={14} margin="5px 0">
          {domToReact(domNode.children, formatOptions)}
        </Text>
      );
    }
  }
};

export const parseDescription = html => {
  return parse(html.replace("&nbsp;", " "));
};

const findPositionFirstError = errorsList => {
  const scrollToErrorList = Object.keys(scrollToError);
  const findSimilar = scrollToErrorList.find(element => errorsList.includes(element));

  return scrollToError[findSimilar];
};

export const handleScrollToError = (errors, isScrollToError) => {
  const errorsList = Object.keys(errors);

  if (isScrollToError && errorsList) {
    window.scrollTo(0, findPositionFirstError(errorsList));
  }
};

const generateCryptoKey = string => {
  const utf8 = new TextEncoder().encode(string);

  if (crypto.subtle && crypto.subtle.digest) {
    return crypto.subtle.digest("SHA-256", utf8).then(hashBuffer => {
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      return hashArray.map(bytes => bytes.toString(16).padStart(2, "0")).join("");
    });
  }
};

export const generateDeviceId = () => {
  let deviceId = window.MediaDeviceInfo ? window.MediaDeviceInfo.deviceId : "";

  if (!deviceId) {
    const { navigator, screen } = window;

    deviceId = navigator.mimeTypes.length;
    deviceId += navigator.userAgent.replace(/\D+/g, "");
    deviceId += navigator.plugins.length;
    deviceId += screen.height || "";
    deviceId += screen.width || "";
    deviceId += screen.pixelDepth || "";
  }

  const generateCryptoKeyPromise = generateCryptoKey(deviceId);

  if (generateCryptoKeyPromise) {
    return generateCryptoKeyPromise;
  }

  return deviceId;
};

export const getNotificationPermission = () => window.Notification && window.Notification.permission;

export const notificationRequestPermission = callback => {
  if (window.Notification && getNotificationPermission() !== notificationPermissionTypes.denied) {
    window.Notification.requestPermission().then(() => {
      callback();
    });
  }
};

export const isHaveFuzuApp = async () => {
  if ("getInstalledRelatedApps" in window.navigator) {
    const relatedApps = await window.navigator.getInstalledRelatedApps();
    return relatedApps.length > 0;
  }
};

export const clearBrowserHistory = () => {
  window.history.pushState(null, null, window.location.href);

  window.window.onpopstate = function () {
    history.go(1);
  };
};

// urlB64ToUint8Array is a magic function that will encode the base64 public key
// to Array buffer which is needed by the subscription option
export const urlB64ToUint8Array = base64String => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
};

export const getPushSubscription = async (inputDeviceId, closeCallback) => {
  const deviceId = inputDeviceId || (await generateDeviceId());
  const serviceWorkerRegistration = await navigator?.serviceWorker?.ready;
  const applicationServerKey = urlB64ToUint8Array(WEB_PUSH_PUBLIC_KEY);
  const subscription = await serviceWorkerRegistration?.pushManager
    ?.subscribe({
      applicationServerKey,
      userVisibleOnly: true
    })
    .catch(() => {
      closeCallback();
    });

  return { deviceId, subscription };
};

export const getUserDeviceData = () => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const { language, userAgent } = window.navigator;

  return { timeZone, language, userAgent };
};

export const buildOnboardingParams = onboardingParams => {
  const user = {
    email: onboardingParams.yourEmail,
    first_name: onboardingParams.name,
    password: onboardingParams.password,
    preferred_country_ids: onboardingParams.countryId && [onboardingParams.countryId],
    applied_job_id: onboardingParams.applicationId,
    terms_privacy_consent: true
  };

  if (onboardingParams.preferredLocations) {
    user.preferred_location_ids = onboardingParams.preferredLocations.map(item => item.value);
  }

  if (onboardingParams?.jobTitlesId || onboardingParams?.pickJob || onboardingParams?.jobTitles) {
    user.interested_job = {
      job_cluster_id: onboardingParams?.jobTitlesId || onboardingParams?.pickJob,
      title: onboardingParams?.jobTitles
    };
  }

  if (onboardingParams?.seniorityLevel) {
    user.seniority_level_id = +onboardingParams?.seniorityLevel;
  }

  if (onboardingParams.mostInterest) {
    // what is it?
    user.seniority_level_id = 6;
  }

  if (onboardingParams.interestAreas) {
    user.interest_area_ids = onboardingParams.interestAreas;
  }

  if (onboardingParams.skills) {
    user.skill_ids = onboardingParams.skills;
  }

  return { user };
};

export const mergeCountries = (allCountries, selectedCountries) => {
  const selectedMap = new Map(selectedCountries.map(country => [country.name, { ...country, selected: true }]));

  return allCountries.map(c => selectedMap.get(c.name) || c);
};
