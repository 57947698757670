import styled from "styled-components";
import { Check } from "@styled-icons/boxicons-regular/Check";

export const CheckboxWrapper = styled.div`
  ${props => props.styled};
`;
export const CheckboxLabel = styled.label`
  display: flex;
  cursor: pointer;
  user-select: none;
  transition: 0.2s ease;
  width: 100%;
  height: 100%;
  ${({ boxedView, borderedView, checked, isFocused, checkedColor, checkedBgColor, borderColor }) => `
    ${
      boxedView
        ? `
    background: #FFFFFF;
    box-shadow: ${checked || isFocused ? "0px 0 0 0 rgba(0, 0, 0, 0.05)" : "0px 5px 15px 3px rgba(0, 0, 0, 0.05)"} ;
    height: 90px;
    border-radius: 5px;
    padding: 0 20px;
    border: 1px solid ${checked || isFocused ? checkedColor : "transparent"};
    &:hover {
      border-color: ${checkedColor};
      box-shadow: 0px 0 0 0 rgba(0, 0, 0, 0.05);
    }
  `
        : ""
    }
    ${
      borderedView
        ? `background: ${checked ? checkedBgColor : "#ffffff"};
    min-height: 60px;
    border-radius: 5px;
    padding: 10px 20px;
    border: 1px solid ${checked || isFocused ? checkedColor : borderColor};
    &:hover {
      border-color: ${checkedColor};
    }`
        : ""
    }
    `}

  > span {
    margin: auto 0;
  }
  ${props => props.styled};

  ${({ $isSetHoverEffect }) =>
    $isSetHoverEffect
      ? `
        &:hover {
          border: 1px solid #408bfc !important;
        }
        `
      : ""}
`;

export const CheckboxControl = styled.input`
  display: none;
  ${props => props.styled};
`;

export const CheckboxMark = styled.div`
  ${props => `
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    flex: 0 0 24px;
    width: 24px;
    height: 24px;
    margin: ${props.$markMargin};
    background-color: ${props.checked ? props.checkedColor : "#fff"};
    border: ${props.checked || props.isFocused ? `2px solid ${props.checkedColor}` : `1px solid ${props.baseColor}`};
    animation:  ${props.checked ? "scaleInSmall 0.3s ease forwards" : "none"};
    will-change: transform;
    transition: 0.2s ease;
  `}
`;

export const CheckboxMarkIcon = styled(Check)`
  width: 20px;
  will-change: transform, fill;
  fill: ${props => (props.checked ? props.markColor : "transparent")};
  transform: ${props => (props.checked ? "scale(1)" : "scale(0.2)")};
  transition: 0.2s ease;
`;
