import React, { useRef, useEffect, useContext, useMemo, useCallback, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import NotificationContext from "contexts/notification";
import { useStandaloneMode } from "b2c/contexts/standalone";
import { useScreenClass } from "react-grid-system";
import { getUserDeviceData } from "b2c/utils/helpers";
import axios from "axios";
import BrandedContext, { isCustomJkuat, isBaronaBrand } from "../contexts/branded";
import useStore from "../contexts/store";
import CountryNotification from "../components/CountryNotification";
import ToastMessage from "../components/ToastMessage";
import { Confirmation } from "../components/ModalContents";
import { useModal, useNotification } from "../../utils/hooks";
import { getNotificationPermission, isHaveFuzuApp } from "../utils/helpers";
import FindJobImage from "../components/ModalContents/FindJob";
import LoginModalCv from "../components/ModalContents/LoginModalCv";
import { GuestVisible } from "../components/WithAuth";
import ThemeContext from "../contexts/theme";
import DomainContext from "../contexts/domain";
import NotificationAddToHomeScreen from "../components/NotificationAddToHomeScreen";
import NotificationModalAllowNotify from "../components/NotificationModalAllowNotify";
import { notificationPermissionTypes } from "../constants/main";

export const useRenderEffect = !window?.hydrated ? useEffect : () => null;

export const useImpressionTrack = ({ rootMargin, disable, ...rest }) => {
  const targetRef = useRef();
  const parentRef = useRef();
  const { track } = useStore("Tracking");
  const { path } = useRouteMatch();
  if (window.IntersectionObserver) {
    const callback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          track("impression", { match_path: path, ...rest });
          const refExists = targetRef?.current instanceof Element;
          refExists && observer?.unobserve(targetRef.current);
        }
      });
    };

    const observer = useMemo(() => {
      return new window.IntersectionObserver(callback, {
        root: parentRef?.current,
        rootMargin
      });
    }, [parentRef?.current]);

    useEffect(() => {
      if (targetRef?.current && !disable) observer?.observe(targetRef.current);

      return () => {
        if (targetRef?.current && !disable) observer?.unobserve(targetRef.current);
      };
    }, [targetRef?.current, disable]);
  }

  return { targetRef, parentRef };
};

export const useCountryDetection = () => {
  const { default_country, ssr } = useStore("initialState");
  const { path } = useRouteMatch();
  const notifyCountry = useNotification(<CountryNotification />, "black300");
  const disableNotification = useNotification(null);
  const isBarona = isBaronaBrand();

  if (isBarona) {
    return null;
  }

  useEffect(() => {
    if (!isBarona && !ssr && (path === "/" || (default_country && !path.includes(default_country?.slug)))) {
      notifyCountry();
    }

    return () => {
      if (!ssr) disableNotification();
    };
  }, [ssr]);
};

export const useConfirmation = (callback, message, cta, buttonProps, title) => {
  const confirm = useModal(<Confirmation />, title);
  let resolve;
  let reject;
  const confirmation = new Promise((res, rej) => {
    resolve = res;
    reject = rej;
  });
  confirmation
    .then(args => {
      callback(...args);
    })
    .catch(() => {
      console.error("Confirmation rejected");
    });

  return (...args) => {
    confirm({
      resolve: () => {
        resolve([...args]);
      },
      reject,
      message,
      cta,
      buttonProps
    });
  };
};

export const useShowToastMessage = () => {
  const { setNotification } = useContext(NotificationContext);
  const { toast_message: toastMessage } = useStore("initialState");

  const notify = useCallback((content, color) => {
    if (setNotification) return setNotification(content, color);
  }, []);

  useEffect(() => {
    if (toastMessage) {
      const keys = Object.keys(toastMessage);

      if (keys.length) {
        notify(<ToastMessage text={toastMessage[keys[0]]} />, "secondary300");
      }
    }
  }, []);
};

export const useGetCurrentCountry = () => {
  const { current_country: currentCountry, default_country: defaultCountry, countries } = useStore("initialState");

  const { path } = useRouteMatch();
  const pathName = path?.replace("/", "");

  if (currentCountry) {
    return currentCountry;
  }

  if (defaultCountry) {
    return defaultCountry;
  }

  if (path) {
    return countries.find(({ slug }) => slug === pathName);
  }

  return {};
};

export const usePromptWhenUserTryNavigateJob = () => {
  const { user } = useStore("User");
  const [activeBanner, setActiveBanner] = useState(false);
  const [activePrompt, setActivePrompt] = useState(false);

  const handleActivePrompt = e => {
    e.preventDefault();
    setActivePrompt(true);
  };

  useEffect(() => {
    if (!user?.id) {
      setActiveBanner(true);
    }
  }, [user, user?.id]);

  return {
    activeBanner,
    handleActivePrompt,
    activePrompt,
    prompt: activePrompt ? <FindJobImage handleClick={() => setActivePrompt(false)} /> : null
  };
};

export const useTriggerModalScroll = isShowOnce => {
  const scrollRef = useRef();
  const showModalCv = useModal(<LoginModalCv />, "", "", false);
  const [showOnce, setShowOnce] = useState(false);
  const country = useGetCurrentCountry();
  const isNigeria = country?.slug === "nigeria";

  const triggerContent = (
    <GuestVisible>
      <div ref={scrollRef} />
    </GuestVisible>
  );

  useEffect(() => {
    let timeout;
    const showModalTrigger = () => {
      const footerHeight = 237;
      const isEndOfPage = scrollRef.current?.getBoundingClientRect().top + footerHeight < window.innerHeight;

      if (isEndOfPage) {
        timeout = setTimeout(() => {
          showModalCv({ isDisableWindowScroll: true });

          if (isShowOnce) {
            setShowOnce(true);
          }
        }, 2000);
      }

      if (!isEndOfPage && timeout) {
        window?.clearTimeout(timeout);
        timeout = 0;
      }
    };

    if (scrollRef.current && !showOnce && isNigeria) {
      window.addEventListener("scroll", showModalTrigger);
    }

    if (showOnce && isNigeria) {
      window.removeEventListener("scroll", showModalTrigger);
      window?.clearTimeout(timeout);
    }

    return () => {
      window.removeEventListener("scroll", showModalTrigger);
      window?.clearTimeout(timeout);
    };
  }, [scrollRef.current, showOnce]);

  return { triggerContent };
};

export const useRedirectToTest = () => {
  const history = useHistory();
  const { brand } = useStore("initialState");
  const { user } = useStore("User");
  const { NCPWDTestCompleted } = useStore("Survey");

  useEffect(() => {
    if (
      !NCPWDTestCompleted &&
      brand.onboarding_survey_id &&
      user?.survey_completed &&
      !user.survey_completed.find(item => String(item) === String(brand.onboarding_survey_id)) &&
      history &&
      history.push
    ) {
      history.push(`/tests/${brand.onboarding_survey_id}`);
    }
  }, []);
};

export const useRedirectToLegacy = history => {
  const { device } = useStore("initialState");

  useEffect(() => {
    if (device?.proxy) {
      history.push("/legacy");
    }
  }, []);
};

export const useRedirectContactPage = externalContactUsUrl => {
  const history = useHistory();

  useEffect(() => {
    if (externalContactUsUrl && history.location.pathname.includes("contact")) {
      window.location.href = externalContactUsUrl;
    }
  }, []);
};

export const useGetPreviousLocation = () => {
  const { location } = useHistory();
  const locationFrom = location?.state?.from;
  const previousPage = typeof locationFrom === "string" ? locationFrom : locationFrom?.pathname;
  const isPreviousApplicationDetailPage = /application/.test(previousPage);

  return { isPreviousApplicationDetailPage };
};

export const useCallExperiment = (experimentId, experimentEvent) => {
  const [isActiveGoogleExperience, setIsActiveGoogleExperience] = useState(false);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];

    dataLayer.push({ event: experimentEvent });

    const promptInterval = window.setTimeout(() => {
      if (window.google_optimize && window.google_optimize.get(experimentId) === "1") {
        setIsActiveGoogleExperience(true);
      }
    }, 100);

    return () => {
      window?.clearTimeout(promptInterval);
    };
  }, []);

  return { isActiveGoogleExperience };
};

export const useInstallPWA = () => {
  const handleOpenNotificationAddToHomeScreen = useModal(<NotificationAddToHomeScreen />, "", false, false);
  const [isShowModal, setIsShowModal] = useState(false);
  const isStandalone = useStandaloneMode();
  const {
    Profile: { userDevice, updateUserDevice }
  } = useStore("User");
  const history = useHistory();
  const isGlobal = window.location.href.includes("global");
  const isSignPage = ["/signup", "/onboarding", "/login", "/global"].includes(history.location.pathname);
  const isMobile = ["xs", "sm"].includes(useScreenClass());
  // const isAndroid = navigator.userAgent.indexOf("android") > -1;
  let timeout;
  useEffect(() => {
    async function renderPwa() {
      const getIsInstalled = await isHaveFuzuApp();
      const { timeZone, language, userAgent } = getUserDeviceData();

      if (!isStandalone && !isSignPage && isMobile && !getIsInstalled && !isGlobal) {
        const handleAppInstalled = () => {
          updateUserDevice(userDevice.deviceId, {
            app_installed: true,
            installation_declined: false,
            timeZone,
            language,
            userAgent
          });
        };
        const handler = event => {
          if (event) {
            event.preventDefault();
          }

          const handleOpenPrompt = () => {
            if (!event) {
              return;
            }

            event.prompt();
          };

          if (userDevice.installation_declined) {
            handleOpenPrompt();
          } else if (!isShowModal) {
            timeout = window.setTimeout(() => {
              handleOpenNotificationAddToHomeScreen({ handleOpenPrompt });
              setIsShowModal(true);
            }, 30000);
          }
        };
        window.addEventListener("beforeinstallprompt", handler);
        window.addEventListener("appinstalled", handleAppInstalled);

        return () => {
          window.removeEventListener("transitionend", handler);
          window.removeEventListener("appinstalled", handleAppInstalled);
        };
      }
    }

    renderPwa();

    return () => {
      window.clearTimeout(timeout);
    };
  }, [history.location.pathname]);
};

export const useShowModalAllowNotify = () => {
  const userStore = useStore("User");
  const { slug } = useContext(BrandedContext);
  const isNCPWD = slug === "ncpwd";
  const customJkuat = isCustomJkuat();

  const isShowNotificationModalAllowNotify =
    !isNCPWD &&
    !customJkuat &&
    userStore?.user &&
    !userStore?.user.notifications_prompted &&
    !userStore?.Profile?.userDevice?.allowPushNotifications &&
    getNotificationPermission() !== notificationPermissionTypes.denied;

  const openNotificationModalAllowNotify = useModal(<NotificationModalAllowNotify />, "");

  return { isShowNotificationModalAllowNotify, openNotificationModalAllowNotify };
};

export const useNCPWD = () => {
  const Theme = useContext(ThemeContext);
  const domain = useContext(DomainContext);
  const isStandalone = useStandaloneMode();
  const path = `${process.env.PROTOCOL}://www.${domain}/`;

  useEffect(() => {
    const isNCPWD = domain === "ncpwd";
    const isIncludesNCPWD = domain.includes("ncpwd");

    if (isIncludesNCPWD && isStandalone) {
      window.location.replace(path);
    }

    if (isNCPWD && !isStandalone) {
      Theme.switchToNcpwd();
    }
  }, [domain, isStandalone]);
};

export const useIsBarona = () => {
  return isBaronaBrand();
};

export const useBarona = () => {
  const Theme = useContext(ThemeContext);
  const isStandalone = useStandaloneMode();
  const { global_brand } = useStore("initialState");
  const { colors } = global_brand || {};

  if (colors && !isStandalone) {
    Theme.switchColors(colors);
  }
};

export const useAddClassStandalone = () => {
  const isStandalone = useStandaloneMode();
  const isStandaloneClassName = "isStandalone";

  useEffect(() => {
    const body = document.getElementsByTagName("body")?.[0];

    if (isStandalone) {
      body.classList.add(isStandaloneClassName);
    }

    return () => {
      if (!isStandalone) {
        body.classList.remove(isStandaloneClassName);
      }
    };
  }, []);
};

export const useLanguageAndCountryUrl = () => {
  const { pathname } = useLocation();

  const match = pathname.match(/^\/([a-z]{2})\/([a-z]{2})\b/i);

  if (match) {
    const country = match[1];
    const language = match[2];

    return `/${country}/${language}`;
  }

  return "";
};

export const useSetCountryAndLocale = (history, isMultiLanguageBrand) => {
  const match = history.location.pathname.match(/^\/([a-z]{2})\/([a-z]{2})\b/i);

  if (match && match[1] && match[2] && isMultiLanguageBrand) {
    const country = match[1];
    const locale = match[2];

    axios.interceptors.request.use(config => {
      config.params = {
        ...config.params,
        country,
        locale
      };
      return config;
    });
  }
};

export const useSetLocalization = () => {
  const { language, changeLanguage } = useTranslation();

  const {
    feature_toggles: { translations_backend: translationsBackend }
  } = useStore("initialState");

  useEffect(() => {
    if (translationsBackend) {
      axios.defaults.headers.common["X-Localization"] = language;
    }
  }, [language]);

  useEffect(() => {
    if (sessionStorage.getItem("language")) {
      changeLanguage(sessionStorage.getItem("language"));
    }
  }, []);
};
