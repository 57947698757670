import React from "react";

import useStore from "b2c/contexts/store";

const LinkWithFullUrl = ({ to, children, ...rest }) => {
  const { base_domain: domain } = useStore("initialState");

  const path = `${process.env.PROTOCOL}://www.${domain}${to === "/" ? "" : to}`;

  return (
    <a href={path} {...rest}>
      {children}
    </a>
  );
};

export default LinkWithFullUrl;
