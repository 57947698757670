import { makeAutoObservable, autorun, reaction } from "mobx";
import "finally-polyfill";

import * as State from "utils/storeHelpers";
import { validateEmail } from "utils/validation";
import { buildOnboardingParams } from "b2c/utils/helpers";
import API from "../../utils/api";

class OnboardingStore {
  setAppState;

  updateUser;

  uploadCv;

  getPersonalOptions;

  track;

  isBaronaState = false;

  constructor(setAppState, updateUser, profileStore, track, globalBrand) {
    makeAutoObservable(this);

    this.updateUser = updateUser;
    this.setAppState = setAppState;
    this.uploadCv = profileStore.uploadCv;
    this.getPersonalOptions = profileStore.getPersonalOptions;
    this.formState = JSON.parse(window.localStorage.getItem("onboardingFormState")) || {};
    this.track = track;
    this.isBaronaState = globalBrand?.name?.includes("Barona");

    autorun(() => {
      const codes = profileStore.personal_options?.phone_codes;
      this.onboardingFlowSteps.details.codes = codes?.priorities?.concat(codes?.common);
    });

    reaction(
      () => this.formState,
      formState => {
        window.localStorage.setItem("onboardingFormState", JSON.stringify(formState));
      }
    );

    reaction(
      () => this.currentStepIndex,
      index => {
        window.localStorage.setItem("onboardingCurrentStep", index);
      }
    );

    reaction(
      () => this.state,
      localState => {
        this.setAppState(localState);
      }
    );
  }

  state = {
    loading: false,
    processing: false,
    type: "",
    message: ""
  };

  flow;

  flowName;

  formState;

  matchingJobClusters = [];

  onboardingFlow = {
    interestJobs: [],
    jobTitles: [],
    interestAreas: [],
    skills: [],
    seniorityLevel: []
  };

  commonCities = [];

  get currentStep() {
    if (this.flow) {
      const interest = JSON.parse(localStorage.getItem("onboardingForm"))?.interest;
      const skipState = JSON.parse(localStorage.getItem("onboardingStepSkipped")) ? "skipped" : "notSkipped";

      const current = this.flow && [...this.flow.entries()].filter(entry => entry[1].current);
      const currentStepObject = current.flat()[1];

      let { slug } = currentStepObject;
      if (currentStepObject[interest]) {
        if (currentStepObject[interest][skipState]) {
          slug = currentStepObject[interest][skipState].slug;
        } else {
          slug = currentStepObject[interest].slug;
        }
      } else if (currentStepObject[skipState]) {
        slug = currentStepObject[skipState].slug;
      }

      return this.onboardingFlowSteps[slug];
    }

    return {};
  }

  get currentStepIndex() {
    return this.flow ? [...this.flow.entries()].filter(entry => entry[1].current).flat()[0] : 1;
  }

  changeStep = (step, index) => {
    this.flow.set(index || this.currentStepIndex, this.onboardingFlowSteps[step]);
  };

  setInitialFlow = (flow, shorterFlow, appliedJobId, candidate) => {
    this.flow = this.flows.get(flow);
    this.flowName = flow;
    const intialIndex = +window.localStorage.getItem("onboardingCurrentStep") || 1;
    this.trackOnboardingEvent("sign_up_flow_started", { initialStep: intialIndex });
    this.flow.set(intialIndex, { ...this.flow.get(intialIndex), current: true });
    if (shorterFlow !== "true") {
      window.localStorage.setItem("onboardingFlow", flow);
    } else {
      const prevOnboardingForm = JSON.parse(localStorage.getItem("onboardingForm"));
      const shorterFlowFormDefaults = {
        email: prevOnboardingForm?.email,
        terms_privacy_consent: true,
        applied_job_id: +appliedJobId,
        candidate
      };
      window.localStorage.setItem("onboardingForm", JSON.stringify(shorterFlowFormDefaults));
    }
  };

  trackOnboardingEvent = (name, params = {}) => this.track(name, { flow: `new_${this.flowName}`, ...params });

  changeNextStep = step => {
    this.changeStep(step, this.currentStepIndex + 1);
  };

  flowProceed = () => {
    const index = this.currentStepIndex;
    this.flow
      .set(index + 1, { ...this.flow.get(index + 1), current: true })
      .set(index, { ...this.flow.get(index), current: false });
  };

  flowReturn = () => {
    const index = this.currentStepIndex;
    this.flow
      .set(index - 1, { ...this.flow.get(index - 1), current: true })
      .set(index, { ...this.flow.get(index), current: false });
  };

  getInterests = () => {
    this.state = State.setLoading(this.state);
    return API.getInterests()
      .then(res => {
        this.onboardingFlowSteps.interests.list = res.data?.interests;
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not retrieve interests", () => this.getInterests());

        throw err;
      });
  };

  getBlueCollarJobTitles = () => {
    this.state = State.setLoading(this.state);
    return API.getBlueCollarJobTitles()
      .then(res => {
        this.onboardingFlowSteps.mostInterested.list = res.data?.job_clusters || [];
        this.onboardingFlow = {
          ...this.onboardingFlow,
          interestJobs: res.data?.job_clusters || []
        };
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not retrieve blue collar job titles", () =>
          this.getBlueCollarJobTitles()
        );

        throw err;
      });
  };

  getSeniorityLevels = () => {
    this.state = State.setLoading(this.state);
    return API.getSeniorityLevels()
      .then(res => {
        this.onboardingFlowSteps.seniorityLevel.list = res?.data?.seniority_levels || [];
        this.onboardingFlow.seniorityLevel = res?.data?.seniority_levels || [];
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not retrieve seniority levels", () =>
          this.getSeniorityLevels()
        );

        throw err;
      });
  };

  getSkills = () => {
    this.state = State.setLoading(this.state);
    const onboardingForm = JSON.parse(localStorage.getItem("onboardingForm"));
    const jobInterestDetails = onboardingForm?.jobInterestDetails;
    if (!jobInterestDetails || !jobInterestDetails.length) return;
    let params = "";

    for (let i = 0; i < jobInterestDetails.length; i++) {
      params += `interest_area_ids[]=${jobInterestDetails[i]}`;
      if (i !== jobInterestDetails.length - 1) {
        params += "&";
      }
    }
    return API.getSkills(params)
      .then(res => {
        this.onboardingFlowSteps.superPower.list = res?.data?.skills || [];
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not retrieve skills", () => this.getSkills());

        throw err;
      });
  };

  getOnboardingSkills = () => {
    this.state = State.setLoading(this.state);
    const onboardingForm = JSON.parse(sessionStorage.getItem("onboardingFlow"));
    const jobInterestDetails = onboardingForm?.interestAreas;

    if (!jobInterestDetails || !jobInterestDetails.length) return;
    let params = "";

    for (let i = 0; i < jobInterestDetails.length; i++) {
      params += `interest_area_ids[]=${jobInterestDetails[i]}`;
      if (i !== jobInterestDetails.length - 1) {
        params += "&";
      }
    }
    return API.getSkills(params)
      .then(res => {
        this.onboardingFlow.skills = res?.data?.skills || [];
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not retrieve skills", () => this.getSkills());

        throw err;
      });
  };

  getInterestAreas = () => {
    this.state = State.setLoading(this.state);
    return API.getInterestAreas()
      .then(res => {
        this.onboardingFlowSteps.jobInterestDetail.list = res?.data?.interest_areas || [];
        this.onboardingFlow.interestAreas = res?.data?.interest_areas || [];
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not retrieve interest areas", () =>
          this.getInterestAreas()
        );

        throw err;
      });
  };

  getMatchingJobClusters = (term, limit = 10) => {
    return API.getInterestedJobs(term, limit).then(res => {
      this.matchingJobClusters = res.data.job_clusters;

      this.onboardingFlow = {
        ...this.onboardingFlow,
        jobTitles: res.data.job_clusters
      };
    });
  };

  updateInterests = ids => {
    this.state = State.setProcessing(this.state);
    return API.updatePreferredCountries(ids)
      .then(res => {
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not update interests", () => this.updateInterests(ids));

        throw err;
      });
  };

  updateInterestedJob = job => {
    this.state = State.setProcessing(this.state);
    return API.updateInterestedJob(job)
      .then(res => {
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not update interested job", () =>
          this.updateInterestedJob(job)
        );

        throw err;
      });
  };

  updatePreferredCountries = ids => {
    this.state = State.setProcessing(this.state);
    return API.updatePreferredCountries(ids)
      .then(res => {
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not update preferred countries", () =>
          this.updatePreferredCountries(ids)
        );

        throw err;
      });
  };

  updateContactDetails = details => {
    this.state = State.setProcessing(this.state);
    return API.updateContactDetails(details)
      .then(res => {
        this.state = State.setNeutral(this.state, res);
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Could not update contact details", () =>
          this.updateContactDetails(details)
        );

        this.onboardingFlowSteps.details.errors = err.response?.data?.errors;

        throw err;
      });
  };

  signUpDetails = (user, referCode) => {
    this.state = State.setProcessing(this.state);
    return API.onboardingSignUpDetails(user, referCode)
      .then(res => {
        this.updateUser(res?.data?.user);
        this.state = State.setNeutral(this.state, res);
        localStorage.setItem("onboardingCurrentStep", "1");

        return res;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, err.response.data?.error || "Could not update credentials", () =>
          this.signUpDetails(user, referCode)
        );

        this.onboardingFlowSteps.credentials.errors = err.response?.data?.errors;

        throw err;
      });
  };

  signUp = (user, referCode, candidate) => {
    this.trackOnboardingEvent("sign_up_trial");
    this.state = State.setProcessing(this.state);

    return API.onboardingSignUp(user, referCode, candidate)
      .then(res => {
        this.updateUser(res?.data?.user);
        this.state = State.setNeutral(this.state, res);
        this.trackOnboardingEvent("sign_up");
        localStorage.setItem("onboardingCurrentStep", "1");

        return res;
      })
      .catch(err => {
        this.trackOnboardingEvent("sign_up_error", { error: err?.response?.data?.error });

        this.state = {
          loading: false,
          type: "sign-up-error",
          status: err?.response?.status || 422,
          action: () => this.signUp(user, referCode, candidate),
          message: err.response.data?.error || "Could not update credentials"
        };

        this.onboardingFlowSteps.credentials.errors = err?.response?.data?.errors;
      });
  };

  confirmEmail = body => {
    return API.confirmEmailRequest(body)
      .then(res => {
        this.updateUser(res?.data?.user);
        this.state = State.setNeutral(this.state, res);
        localStorage.setItem("onboardingCurrentStep", "1");

        return res;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Error", () => this.confirmEmail(body));

        this.onboardingFlowSteps.credentials.errors = err.response?.data?.errors;

        throw err;
      });
  };

  getCommonCities = (countryId, search) => {
    return API.getCommonCities(countryId, search)
      .then(res => {
        this.commonCities = res.data.results;
      })
      .catch(err => {
        this.state = State.setError(this.state, err, "Error", () => this.confirmEmail(body));

        this.onboardingFlowSteps.credentials.errors = err.response?.data?.errors;

        throw err;
      });
  };

  clearCommonCities = () => {
    this.commonCities = [];
  };

  debounceValidate = email => {
    const { test, error } = validateEmail(email);

    if (test) {
      this.state = State.setProcessing(this.state);
      return API.debounceValidateEmail(email)
        .then(res => {
          this.state = State.setNeutral(this.state, res);
          if (!res?.data?.valid) this.onboardingFlowSteps.validate.error = "Email address does not exist";
          else this.formState = { ...this.formState, email };

          return res?.data;
        })
        .then(({ valid, message }) => {
          return valid ? Promise.resolve() : Promise.reject(message);
        })
        .catch(err => {
          this.state = State.setError(this.state, err, "Could not validate email", () => this.debounceValidate(email));
          if (err?.response?.data?.message) this.onboardingFlowSteps.validate.error = err?.response?.data?.message;
          throw err;
        });
    }
    this.onboardingFlowSteps.validate.error = error;
    return Promise.reject(error);
  };

  buildOnboardingFlowBody = (name, password) => {
    const referCode = localStorage.getItem("referCode");
    const prevOnboardingForm = JSON.parse(localStorage.getItem("onboardingForm"));

    if (!prevOnboardingForm) {
      const prevOnboardingFlow = (() => {
        try {
          return JSON.parse(sessionStorage.getItem("onboardingFlow"));
        } catch (e) {
          console.error("error", e);
          return null;
        }
      })();
      if (!prevOnboardingFlow) return {};

      const applicationId = prevOnboardingFlow.applicationId
        ? { applicationId: Number(prevOnboardingFlow.applicationId) }
        : {};
      const paramsValues = {
        ...prevOnboardingFlow,
        ...applicationId
      };
      const { user } = buildOnboardingParams(paramsValues);
      return { ...prevOnboardingFlow, user, referCode };
    }

    const user = {
      email: prevOnboardingForm.email,
      first_name: name,
      password,
      preferred_country_ids: prevOnboardingForm.preferredCountryId && [prevOnboardingForm.preferredCountryId],
      applied_job_id: prevOnboardingForm.applied_job_id,
      interested_job: {
        job_cluster_id: prevOnboardingForm?.job_cluster?.job_cluster_id || prevOnboardingForm?.mostInterest,
        title: prevOnboardingForm?.job_cluster?.title
      }
    };

    if (prevOnboardingForm?.seniorityLevel) {
      user.seniority_level_id = +prevOnboardingForm?.seniorityLevel;
    }

    if ("terms_privacy_consent" in prevOnboardingForm) {
      user.terms_privacy_consent = prevOnboardingForm.terms_privacy_consent;
    } else {
      user.terms_privacy_consent = true;
    }

    if (prevOnboardingForm.mostInterest) {
      user.seniority_level_id = 6;
    }

    if (prevOnboardingForm.career_interest_ids) {
      user.career_interest_ids = prevOnboardingForm.careerInterestIds;
    }

    if (prevOnboardingForm.jobInterestDetails) {
      user.interest_area_ids = prevOnboardingForm.jobInterestDetails;
    }

    if (prevOnboardingForm.superPower) {
      user.skill_ids = prevOnboardingForm.superPower;
    }

    return { ...prevOnboardingForm, user, referCode };
  };

  onboardingFlowSteps = {
    country: {
      step: 1,
      slug: "country",
      name: "onboarding.country.name",
      title: "onboarding.country.title",
      description: "onboarding.country.description",
      validate: form => {
        const preferredCountryId = Array.from(form.country)
          .filter(item => item.checked)
          .map(item => +item.value)[0];

        if (!preferredCountryId) {
          return "onboarding.country.error";
        }
      },
      onSubmit: form => {
        const preferredCountryId = Array.from(form.country)
          .filter(item => item.checked)
          .map(item => +item.value)[0];

        const prevOnboardingForm = JSON.parse(localStorage.getItem("onboardingForm"));

        const onboardingForm = {
          ...prevOnboardingForm,
          preferredCountryId
        };
        localStorage.setItem("onboardingForm", JSON.stringify(onboardingForm));
        return onboardingForm;
      }
    },
    interests: {
      step: 2,
      slug: "interests",
      name: "onboarding.interests.name",
      title: "onboarding.interests.title",
      description: "onboarding.interests.description",
      list: [],
      onMount: this.getInterests,
      validate: form => {
        const interest = Array.from(form.interest)
          .filter(item => item.checked)
          .map(item => item.value)[0];

        if (!interest) {
          return "onboarding.interests.error";
        }
      },
      onSubmit: form => {
        const interest = Array.from(form.interest)
          .filter(item => item.checked)
          .map(item => item.value)[0];

        const prevOnboardingForm = JSON.parse(localStorage.getItem("onboardingForm"));

        const onboardingForm = {
          ...prevOnboardingForm,
          interest
        };
        localStorage.setItem("onboardingForm", JSON.stringify(onboardingForm));
        return onboardingForm;
      }
    },
    job_type: {
      slug: "job_type",
      name: "Job type",
      title: "What type of jobs you are interested in?",
      description: "This will help us guide you to best matching jobs! Select one.",
      onSubmit: form => {
        const onboardingForm = {
          ...JSON.parse(localStorage.getItem("onboardingForm")),
          jobType: form.type[0].checked
        };
        localStorage.setItem("onboardingForm", JSON.stringify(onboardingForm));
        return onboardingForm;
      }
    },
    mostInterested: {
      slug: "mostInterested",
      name: "onboarding.your_interests.name",
      title: "onboarding.your_interests.title",
      description: "onboarding.your_interests.description",
      step: 3,
      list: [],
      validate: form => {
        const mostInterest = Array.from(form.mostInterested)
          .filter(item => item.checked)
          .map(item => item.value)[0];

        if (!mostInterest) {
          return "onboarding.your_interests.error";
        }
      },
      onMount: this.getBlueCollarJobTitles,
      onSubmit: (form, isUpdateRegistration) => {
        const mostInterest = Array.from(form.mostInterested)
          .filter(item => item.checked)
          .map(item => item.value)[0];

        const prevOnboardingForm = JSON.parse(localStorage.getItem("onboardingForm"));
        const onboardingForm = {
          ...prevOnboardingForm,
          mostInterest
        };
        localStorage.setItem("onboardingForm", JSON.stringify(onboardingForm));

        if (isUpdateRegistration) {
          const submitForm = this.buildOnboardingFlowBody(form.name?.value, form.password?.value);

          localStorage.setItem("onboardingForm", JSON.stringify(submitForm));
          return this.signUpDetails({ user: submitForm.user }, submitForm.referCode);
        }

        return onboardingForm;
      }
    },
    job_interest: {
      slug: "job_interest",
      name: "Your interests",
      title: "Which job are you most interested in?",
      description: "Select one (you may add more later).",
      onSubmit: form => {
        const onboardingForm = {
          ...JSON.parse(localStorage.getItem("onboardingForm")),
          jobType: form.type[0].checked
        };
        localStorage.setItem("onboardingForm", JSON.stringify(onboardingForm));
        return onboardingForm;
      }
    },
    share_exp: {
      slug: "share_exp",
      name: "Preparing your application",
      title: "Share your experience with Employer",
      description:
        "If you’re looking to relocate, please indicate the country you want us to find your next career opportunity.",
      skip: "I will do it later",
      onSubmit: form => {
        this.changeNextStep(form?.share_exp?.value);
        return Promise.resolve();
      }
    },
    upload_cv: {
      slug: "upload_cv",
      name: "Upload your CV",
      title: "Upload your CV",
      description: "Uploading your CV will result in better job alerts for you.",
      skip: "I don’t have a CV right now",
      onSubmit: form => {
        this.state = State.setProcessing(this.state);
        return this.uploadCv(form?.upload_cv?.files[0]).finally(() => {
          this.state = State.setNeutral(this.state);
        });
      }
    },
    interested_job: {
      slug: "interested_job",
      name: "onboarding.interested_job.name",
      title: "onboarding.interested_job.title",
      description: "onboarding.interested_job.description",
      skip: "onboarding.interested_job.skip",
      step: 3,
      validate: form => {
        const idSelected = !Number.isNaN(+form.jobInput?.id);
        const jobClusterId = idSelected ? +form.jobInput?.id : undefined;
        const title = idSelected ? undefined : form.jobInput?.value;

        if (!idSelected) {
          localStorage.setItem("onboardingStepSkipped", "true");
        }

        if (!jobClusterId && !title) {
          return "onboarding.interested_job.error";
        }
      },
      onSubmit: form => {
        const idSelected = !Number.isNaN(+form.jobInput?.id);
        const job_cluster = {
          job_cluster_id: idSelected ? +form.jobInput?.id : undefined,
          title: idSelected ? undefined : form.jobInput?.value
        };
        if (idSelected) {
          localStorage.setItem("onboardingStepSkipped", "false");
        }
        const prevOnboardingForm = JSON.parse(localStorage.getItem("onboardingForm"));

        const onboardingForm = {
          ...prevOnboardingForm,
          job_cluster
        };
        localStorage.setItem("onboardingForm", JSON.stringify(onboardingForm));
        return onboardingForm;
      }
    },
    jobInterestDetail: {
      slug: "jobInterestDetail",
      name: "onboarding.job_interest_detail.name",
      title: "onboarding.job_interest_detail.title",
      description: "onboarding.job_interest_detail.description",
      list: [],
      onMount: this.getInterestAreas,
      step: 3,
      validate: form => {
        const jobInterestDetails = Array.from(form.job)
          .filter(item => item.checked)
          .map(item => item?.id?.replace("uid-", ""));

        if (!jobInterestDetails || !jobInterestDetails.length) {
          return "onboarding.job_interest_detail.error";
        }
      },
      onSubmit: form => {
        const jobInterestDetails = Array.from(form.job)
          .filter(item => item.checked)
          .map(item => item?.id?.replace("uid-", ""));

        const prevOnboardingForm = JSON.parse(localStorage.getItem("onboardingForm"));

        const onboardingForm = {
          ...prevOnboardingForm,
          jobInterestDetails
        };

        localStorage.setItem("onboardingForm", JSON.stringify(onboardingForm));
        return onboardingForm;
      }
    },
    superPower: {
      slug: "superPower",
      name: "onboarding.super_power.name",
      title: "onboarding.super_power.title",
      description: "onboarding.super_power.description",
      list: [],
      step: 3,
      onMount: this.getSkills,
      validate: form => {
        if (form.superPower) {
          const superPower = Array.from(form.superPower)
            .filter(item => item.checked)
            .map(item => item.value);

          if (!superPower || !superPower.length) {
            return "onboarding.super_power.error";
          }
        }
      },
      onSubmit: form => {
        if (form.superPower) {
          const superPower = Array.from(form.superPower)
            .filter(item => item.checked)
            .map(item => item.value);

          const prevOnboardingForm = JSON.parse(localStorage.getItem("onboardingForm"));

          const onboardingForm = {
            ...prevOnboardingForm,
            superPower
          };

          localStorage.setItem("onboardingForm", JSON.stringify(onboardingForm));
          return onboardingForm;
        }
      }
    },
    seniorityLevel: {
      slug: "seniorityLevel",
      name: "onboarding.seniority_level.name",
      title: "onboarding.seniority_level.title",
      description: "onboarding.seniority_level.description",
      step: 4,
      list: [],
      validate: form => {
        const jobInterestDetails = Array.from(form.job)
          .filter(item => item.checked)
          .map(item => item?.id?.replace("uid-", ""));

        if (!jobInterestDetails || !jobInterestDetails.length) {
          return "onboarding.seniority_level.error";
        }
      },
      onMount: this.getSeniorityLevels,
      onSubmit: (form, isUpdateRegistration) => {
        const seniorityLevel = Array.from(form.job)
          .filter(item => item.checked)
          .map(item => item.value)[0];

        const prevOnboardingForm = JSON.parse(localStorage.getItem("onboardingForm"));
        const onboardingForm = {
          ...prevOnboardingForm,
          seniorityLevel
        };
        localStorage.setItem("onboardingForm", JSON.stringify(onboardingForm));

        if (isUpdateRegistration) {
          const submitForm = this.buildOnboardingFlowBody(form.name?.value, form.password?.value);

          localStorage.setItem("onboardingForm", JSON.stringify(submitForm));
          return this.signUpDetails({ user: submitForm.user }, submitForm.referCode);
        }

        return onboardingForm;
      }
    },
    details: {
      slug: "details",
      name: "Contact info",
      title: "Let employers know you",
      description:
        "If you’re looking to relocate, please indicate the country you want us to find your next career opportunity.",
      codes: [],
      errors: null,
      onMount: async () => {
        this.state = State.setLoading(this.state);
        await this.getPersonalOptions();
        this.state = State.setNeutral(this.state);
      },
      onSubmit: () => {
        // const details = {
        //   first_name: form.firstName?.value,
        //   last_name: form.lastName?.value,
        //   phone: {
        //     code: form.codes?.id,
        //     number: form.phone?.value
        //   }
        // };
      }
    },
    credentials: {
      slug: "credentials",
      name: "onboarding.credentials.name",
      title: "onboarding.credentials.title",
      step: 6,
      validate: form => {
        const firstName = form.name?.value;
        const password = form.password?.value;

        if (!firstName) {
          return { name: "onboarding.credentials.error_1" };
        }

        if (!password) {
          return { password: "onboarding.credentials.error_2" };
        }

        if (password.length < 8) {
          return { password: "onboarding.credentials.error_3" };
        }
      },
      onSubmit: form => {
        const onboardingForm = this.buildOnboardingFlowBody(form.name?.value, form.password?.value);
        const onboardingFormLocalStorage = JSON.parse(localStorage.getItem("onboardingForm"));

        localStorage.setItem("onboardingForm", JSON.stringify(onboardingForm));
        return this.signUp(
          { user: onboardingForm.user },
          onboardingForm.referCode,
          onboardingFormLocalStorage.candidate
        );
      }
    },
    emailConfirmation: {
      slug: "emailConfirmation",
      name: "Create account",
      title: "Confirm you email",
      step: 6,
      validate: form => {
        const email = form.email?.value;
        const firstName = form.firstName?.value;
        if (!email) {
          return "Please enter your email address";
        }
        if (!firstName) {
          return "Please enter your name";
        }
        return !/^\w+([\w\S]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email) ? "Invalid email" : false;
      },
      onSubmit: form => {
        const email = form.email?.value;
        const firstName = form.firstName?.value;
        const confirmedEmailAndName = { email, firstName };

        const prevOnboardingForm = JSON.parse(localStorage.getItem("onboardingForm"));

        const onboardingForm = {
          ...prevOnboardingForm,
          confirmedEmailAndName
        };
        localStorage.setItem("onboardingForm", JSON.stringify(onboardingForm));
        return this.confirmEmail({ user: { first_name: firstName, email } });
      }
    },
    validate: {
      slug: "validate",
      name: "onboarding.email.name",
      title: "onboarding.email.title",
      step: 5,
      validate: form => {
        const email = form.email?.value;
        if (!email) {
          return "onboarding.email.error";
        }
        return !/^\w+([\w\S]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email) ? "Invalid email" : false;
      },
      onSubmit: form => {
        const email = form.email?.value;

        const prevOnboardingForm = JSON.parse(localStorage.getItem("onboardingForm"));

        const onboardingForm = {
          ...prevOnboardingForm,
          email
        };
        localStorage.setItem("onboardingForm", JSON.stringify(onboardingForm));
        return onboardingForm;
      }
    }
  };

  genericFlow = new Map([
    [1, this.onboardingFlowSteps.country],
    [2, this.onboardingFlowSteps.interests],
    [
      3,
      {
        skilled: this.onboardingFlowSteps.mostInterested,
        expert: this.onboardingFlowSteps.interested_job
      }
    ],
    [
      4,
      {
        skilled: this.onboardingFlowSteps.validate,
        expert: {
          name: "Your Interests",
          skipped: this.onboardingFlowSteps.jobInterestDetail,
          notSkipped: this.onboardingFlowSteps.seniorityLevel
        }
      }
    ],
    [
      5,
      {
        skilled: this.onboardingFlowSteps.credentials,
        expert: {
          skipped: this.onboardingFlowSteps.superPower,
          notSkipped: this.onboardingFlowSteps.validate
        }
      }
    ],
    [
      6,
      {
        skipped: this.onboardingFlowSteps.seniorityLevel,
        notSkipped: this.onboardingFlowSteps.credentials
      }
    ],
    [
      7,
      {
        skipped: this.onboardingFlowSteps.validate,
        notSkipped: this.onboardingFlowSteps.credentials
      }
    ],
    [8, this.onboardingFlowSteps.credentials],
    [9, this.onboardingFlowSteps.emailConfirmation]
  ]);

  shortFlow = new Map([
    [1, this.onboardingFlowSteps.country],
    [2, this.onboardingFlowSteps.interests],
    [
      3,
      {
        skilled: this.onboardingFlowSteps.mostInterested,
        expert: this.onboardingFlowSteps.interested_job
      }
    ],
    [
      4,
      {
        name: "Your Interests",
        skipped: this.onboardingFlowSteps.jobInterestDetail,
        notSkipped: this.onboardingFlowSteps.seniorityLevel
      }
    ],
    [
      5,
      {
        skipped: this.onboardingFlowSteps.superPower,
        notSkipped: this.onboardingFlowSteps.seniorityLevel
      }
    ],
    [
      6,
      {
        skipped: this.onboardingFlowSteps.seniorityLevel,
        notSkipped: this.onboardingFlowSteps.seniorityLevel
      }
    ]
  ]);

  icEcFlow = new Map([
    [1, this.onboardingFlowSteps.validate],
    [2, this.onboardingFlowSteps.credentials],
    [3, this.onboardingFlowSteps.details]
  ]);

  shorterFlow = new Map([
    [1, this.onboardingFlowSteps.validate],
    [2, this.onboardingFlowSteps.credentials]
  ]);

  flows = new Map([
    ["ic_ec", this.icEcFlow],
    ["generic", this.genericFlow],
    ["short", this.shortFlow],
    ["shorter", this.shorterFlow]
  ]);
}

export default OnboardingStore;
